import { Dimensions, StyleSheet } from 'react-native';

import { theme } from '../../styles/theme';

const windowWidth = Dimensions.get('window').width;

export default StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 16,
    paddingHorizontal: 16,
    alignItems: 'center',
  },

  title: {
    ...theme.typography.title1,
    fontSize: 24,
    marginTop: 64,
    textAlign: 'center',
  },

  subtitle: {
    ...theme.typography.title6,
    opacity: 0.8,
    marginTop: 24,
    fontSize: windowWidth < 500 ? 12 : 16,
    textAlign: 'center',
  },

  buttonsContainer: {
    marginTop: 48,
  },
});
