import { useEffect, useState } from 'react';

import { loadStripe, StripeError } from '@stripe/stripe-js';
import { Route, Routes, useSearchParams } from 'react-router-dom';

import { DownloadAppScreen, StripeErrorScreen } from './screens';

const stripePromise = loadStripe(
  process.env['REACT_APP_STRIPE_PUBLISHABLE_KEY'] || ''
);

const App = () => {
  const [searchParams] = useSearchParams();

  const sessionId = searchParams.get('session_id');

  const [isLoading, setIsLoading] = useState(true);
  const [stripeError, setStripeError] = useState<StripeError | null>(null);

  useEffect(() => {
    async function redirectToCheckout() {
      if (!sessionId) {
        return;
      }

      const stripe = await stripePromise;

      try {
        await stripe?.redirectToCheckout({
          sessionId,
        });
      } catch (error) {
        setStripeError(error as StripeError);
      } finally {
        setIsLoading(false);
      }
    }

    setIsLoading(true);
    redirectToCheckout();
  }, [sessionId]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <StripeErrorScreen stripeError={stripeError} isLoading={isLoading} />
        }
      />
      <Route path="/download" element={<DownloadAppScreen />} />
    </Routes>
  );
};

export default App;
