import { StripeError } from '@stripe/stripe-js';
import { Text, View } from 'react-native';

import LoadingSpinnerScreen from '../../components/loadingSpinnerScreen';
import styles from './StripeErrorScreen.styles';

type StripeErrorProps = {
  isLoading: boolean;
  stripeError: StripeError | null;
};

export default function StripeErrorScreen({
  stripeError,
  isLoading,
}: StripeErrorProps) {
  // Error for logging to sentry
  const stripeErrorMessage = stripeError?.message;

  if (isLoading) {
    return <LoadingSpinnerScreen />;
  }

  return (
    <View style={styles.container}>
      <View>
        <Text style={styles.title}>Stripe Error</Text>
        <Text style={styles.subtitle}>
          Please try again.. Something broke off
        </Text>
      </View>
    </View>
  );
}
